<template>
  <div class="house-master h-100_ d-f jc-sb">
    <div style="width: 212px;background: #fff;
    flex-direction: column;
    border: 1px solid #d7d8d9;" class="h-100_">
      <div style="line-height: 45px;
    padding: 0 12px 0 16px;
    border-bottom: 1px solid #d7d8d9;" class="c-p d-f jc-sb ai-c fw-b" @click="searchByItem('')">
        全部
      </div>
      <div v-for="(item, idx) in paramTypeCodeList"
        :style="moreInfo.dormTypeName == item ? 'background: rgb(240, 240, 240);' : ''" :key="item + idx"
        class="title d-f jc-sb ai-c fw-b c-p" @click="searchByItem(item)" style="padding: 0 12px 0 16px;
    height: 40px;
    font-size: 13px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);">
        {{ item }}
      </div>
    </div>
    <div class="room-body h-100_ no-side" style="    width: calc(100% - 250px);
    margin: 0 0 0 24px;">
      <house-master-body-header />
      <div class="room-body-content w-100_">
        <common-table short :data="tableList" style="width: 100%" @selectionChange="handleSelectionChange"
          :setQuerySearchPagesize="setQuerySearchPagesize" :setQuerySearchPageNum="setQuerySearchPageNum"
          :querySearch="querySearch" :queryTableData="getTableList" :total="total">
          <el-table-column v-if="!isMM" type="selection" width="55" align="center">
          </el-table-column>
          <el-table-column label="序号" type="index" width="100">
            <template slot-scope="scope">
              {{
                (querySearch.pageNum - 1) * querySearch.pageSize +
                1 +
                scope.$index
              }}
            </template>
          </el-table-column>
          <el-table-column prop="dormName" width="80" label="姓名">
          </el-table-column>

          <!-- <el-table-column prop="ssex" width="60" label="性别">
            <template slot-scope="scope">
              {{ ["男", "女", "保密"][scope.row.ssex] }}
            </template>
          </el-table-column> -->
          <el-table-column prop="phone" width="180" label="手机号"> </el-table-column>
          <el-table-column prop="dormTypeName" width="180" label="宿管类型"> </el-table-column>
          <el-table-column width="180" prop="identityNo" label="身份证号码">
          </el-table-column>
          <el-table-column prop="buildingNameList" label="已分配的楼栋"> </el-table-column>

          <el-table-column fixed="right" align="left" width="100" prop="address" label="操作" v-if="!isMM">
            <template slot-scope="scope">
              <div class="d-f ai-c jc-sa set">
                <img title="修改" class="c-p el-icon-edit-outline" src="@/assets/edit.svg" @click="edit(scope.row)" />

                <img title="房间管理" src="@/assets/roomManager.svg" class="el-icon-delete c-p" @click="manager(scope.row)"
                  alt="" />
                <img title="删除" src="@/assets/delete.svg" class="el-icon-delete c-p" @click="deleteF(scope.row)" />
              </div>
            </template>
          </el-table-column>
        </common-table>
      </div>
      <add :visible.sync="showEdit" @submit="finishEdit" @fix="editPass(rowItem)" showFixPass :title="'编辑宿管'"
        :data="rowItem" :list="paramTypeCodeList" />
    </div>
    <el-dialog :visible.sync="passwordVisible" width="300px" custom-class="other-dialog" top="147px" title="修改密码"
      :close-on-click-modal="false">
      <el-input style="margin: 10px 0" placeholder="请输入密码" v-model="newPassword" show-password></el-input>
      <el-input placeholder="请确认密码" v-model="confirmPassword" show-password></el-input>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="sureModifyPass" type="primary">确认</el-button>
        <el-button @click="passwordVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } =
  createNamespacedHelpers("housemaster");
import houseMasterBodyHeader from "./houseMasterBodyHeader.vue";
import Add from "./houseMasterBodyAdd.vue";
import { storeChecked } from "../common/index";
import ajax from "../../plugins/axios";
import { getSchoolId } from '../common/store';
export default {
  name: "house-master",
  components: {
    houseMasterBodyHeader,
    Add,
  },
  data() {
    return {
      showEdit: false,
      modifyVisible: false,
      modifyName: "",
      deleteVisible: false,
      current: 1,
      rowItem: null,
      passwordVisible: false,
      newPassword: "",
      confirmPassword: "",
      userId: "",
      paramTypeCodeList: []
    };
  },
  methods: {
    ...mapActions(["getTableList", "modifyMaster", "deleteMaster", "getHoustMasterType"]),
    ...mapMutations([
      "setSectionItem",
      "setQuerySearchPagesize",
      "setQuerySearchPageNum",
      "setMasterName",
      "setMoreInfo"
    ]),
    searchByItem(item) {
      this.setMoreInfo({ dormTypeName: item });
      this.getTableList({ pageNum: 1 });
    },
    handleSelectionChange(val) {
      // 多选的item
      this.setSectionItem(val);
    },
    deleteFunc() {
      //   删除
      this.deleteVisible = false;
    },
    editPass(val) {
      this.userId = val.userId;
      this.newPassword = "";
      this.confirmPassword = "";
      this.passwordVisible = true
    },
    modify(val) {
      //  修改
      console.log(val);
      this.modifyVisible = false;
    },
    sureModifyPass() {
      if (!(this.newPassword && this.confirmPassword)) {
        this.$message.error("密码请填写完整");
      }
      if (this.newPassword == this.confirmPassword) {
        ajax.put('/lock/user/updatePasswordWithoutCondition', { userId: this.userId, password: this.newPassword, }).then(() => {
          this.$message.success("密码修改成功");
          this.passwordVisible = false
        });
      } else {
        this.$message.error("输入的新密码不一致");
      }
    },
    finishEdit(data) {
      //  编辑完成获取数据；
      const { dormName, identityNo, phone, ssex, id, dormTypeName } = data;
      this.modifyMaster({ dormName, identityNo, phone, ssex, id, dormTypeName }).then(
        (res) => {
          this.showEdit = false;
          this.$message.success("修改宿管信息成功");
        }
      );
    },
    edit(row) {
      // 编辑当前数据
      this.rowItem = { ...row };
      this.showEdit = true;
    },
    deleteF(row) {
      // 删除当前数据
      this.$confirm("你确定要删除当前宿管信息吗？", "宿管删除确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.deleteMaster(row.id);
      });
    },
    manager(row) {
      this.setMasterName(row.dormName);
      this.$router.push("/housemaster/manager/" + row.id);
    },
  },
  mounted() {
    this.getHoustMasterType().then(res => {
      console.log(res)
      this.paramTypeCodeList = res.find(it => it.paramCode == "dormType").paramValue.split('-')
    })
  },
  computed: {
    ...mapState(["tableList", "chooseType", "querySearch", "total", "moreInfo"]),
    cellsData() {
      console.log(this.chooseType == "cell");
      return this.chooseType == "cell";
    },
    isMM() {
      return this.$store.state.login.isMM;
    },
  },
};
</script>

<style lang="scss" scoped>
.house-master {
  background: #f4f5f6;
  padding: 24px;
}
</style>
