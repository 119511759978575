<template>
  <div>
    <div class="room-body-header d-f ai-c jc-sb">
      <div class="d-f ai-c jc-sb">
        <my-input
          placeholder="请输入姓名、手机、身份证号"
          v-model="searchQuery"
          clearable
          @clear="search(false)"
          style="width: 220px; margin-right: 8px"
        />
        <el-button type="primary" @click="search"> 搜 索</el-button>
        <el-button
          v-if="!isMM"
          title="保存"
          @click="exportData(false)"
          :style="
            !(sectionItem && sectionItem.length)
              ? 'cursor:not-allowed;opacity:.5'
              : 'cursor:pointer'
          "
          :disabled="!(sectionItem && sectionItem.length)"
        >
          保存
        </el-button>
        <el-button
          v-if="!isMM"
          @click="batchDelVisible = true"
          :disabled="!(sectionItem && sectionItem.length)"
          >删除</el-button
        >
      </div>
      <div class="d-f ai-c">
        <common-daoru
          v-if="!isMM"
          title="批量导入"
          @click="importVisible = true"
          type="text"
          class="icon-btn-box"
          style="margin-left: 20px"
        >
        </common-daoru>

        <common-daochu
          v-if="!isMM"
          title="全部导出"
          @click="exportData(true)"
          type="text"
          class="icon-btn-box"
        >
        </common-daochu>
      </div>
      <div>
        <!-- <el-button v-if="!isMM" @click="importVisible = true"
          >批量导入</el-button
        > -->

        <el-button v-if="!isMM" type="primaryHover" @click="drawer = true"
          >添加宿管</el-button
        >
        <!-- <el-button
          v-if="!isMM"
          type="primary"
          :disabled="!(sectionItem && sectionItem.length)"
          @click="exportData"
          >导出数据</el-button
        > -->
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      title="批量导入"
      :visible.sync="importVisible"
      width="360px"
      style="padding: 0"
      custom-class="dialog-import"
    >
      <el-alert
        v-if="filesName"
        :title="filesName"
        type="success"
        effect="dark"
        @close="filesName = ''"
      >
      </el-alert>
      <div v-else class="d-f ai-c" style="flex-direction: column">
        <el-button @click="downloadTemplate" type="text" style="width: auto"
          >点击下载宿管信息模板</el-button
        >

        <el-button @click="$refs.file.click()" style="margin: 5px auto"
          ><img style="width: 10px" src="@/assets/upload.png" alt="" />
          上传文件</el-button
        >
        <input
          @change="fileChange"
          ref="file"
          type="file"
          style="display: block; width: 1px; height: 0; opacity: 0"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <div slot="tip" class="f-12 c-9 ta-c">支持扩展名：xlsx</div>
      </div>
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="importVisible = false">取消</el-button>
        <el-button type="primary" @click="sure">确认</el-button>
      </div>
    </el-dialog>
    <add :visible.sync="drawer" :title="'添加宿管'" @submit="addHouseMaster" />
    <err-excel
      ref="err"
      @reImport="importVisible = true"
      @exportErrMessage="exportErrMessage"
      :errCloumn="columns"
      :batAddFunction="batAddHostMaster"
      :getTableData="getTableList"
    ></err-excel>
    <el-dialog
      :close-on-click-modal="false"
      custom-class="room-header-add"
      title="批量删除"
      :visible.sync="batchDelVisible"
      width="360px"
    >
      是否批量删除选中的{{ sectionItem ? sectionItem.length || 0 : 0 }}条数据？
      <div slot="footer" class="d-f jc-c ai-c">
        <el-button @click="batchDelVisible = false">取消</el-button>
        <el-button type="primary" @click="batchDeleteSure">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } =
  createNamespacedHelpers("housemaster");
import Add from "./houseMasterBodyAdd.vue";
import ErrExcel from "../common/errorExcel.vue";
import CommonDaoru from "../../components/commonDaoru.vue";
import CommonDaochu from "../../components/commonDaochu.vue";
export default {
  name: "room-body-header",
  components: { Add, ErrExcel, CommonDaoru, CommonDaochu },
  data() {
    return {
      batchDelVisible: false,
      searchQuery: "",
      visible: false,
      drawer: false,
      importVisible: false,
      batchAdd: {
        build: "", // 楼栋
        cell: "", //  楼层数量
        room: "", // 房间
      },
      fileList: [],
      filesName: "",
      File: null,
      columns: [
        { label: "姓名", prop: "name", width: "80" },
        { label: "身份证号码", prop: "identityNo", width: "180" },
        { label: "电话号码", prop: "phone", width: "150" },
      ],
    };
  },
  methods: {
    ...mapActions([
      "getTableList",
      "batAdd",
      "addMaster",
      "saveRoomInfo",
      "getExcelTemplate",
      "uploadExcelTemplate",
      "getExcel",
      "getErrMasterExcel",
      "batAddHostMaster",
      "batchDel",
    ]),
    ...mapMutations(["setSearchValue"]),
    search(f = true) {
      this.setSearchValue(this.searchQuery);
      if (this.searchQuery || !f) {
        this.getTableList({ pageNum: 1 });
      }
    },
    sure() {
      // 批量添加确认
      const fd = new FormData();
      fd.append("file", this.File);
      this.uploadExcelTemplate(fd).catch((err) => this.$refs.err.getError(err));
      this.importVisible = false;
    },
    addHouseMaster(params) {
      this.addMaster(params).then(() => {
        this.drawer = false;
      });
    },
    downloadTemplate() {
      this.getExcelTemplate();
    },
    fileChange(e) {
      const file = e.target.files[0];
      this.filesName = file.name;
      this.File = file;
    },
    exportData(all = false) {
      this.getExcel(all ? 0 :{
        exportIds:  this.sectionItem.map((it) => it.id).join(","),
      });
    },
    exportErrMessage(uuid) {
      this.getErrMasterExcel({ uuid });
    },
    batchDeleteSure() {
      // 批量删除确认
      this.batchDel(this.sectionItem.map((it) => it.id));
      this.batchDelVisible = false;
    },
  },
  watch: {
    visible() {
      this.batchAdd = {
        build: "", // 楼栋
        cell: "", //  楼层数量
        room: "", // 房间
      };
    },
    importVisible() {
      this.filesName = "";
    },
  },
  computed: {
    ...mapState(["sectionItem"]),
    isMM() {
      return this.$store.state.login.isMM;
    },
  },
};
</script>

<style lang="scss" scoped>
.room-body-header {
  height: 60px;
  background: #fff;
  padding: 0 24px;
}
.room-header-add {
  .el-input {
    margin-bottom: 12px;
    width: 100%;
  }
  button {
    margin: 0 4px;
    width: 88px;
  }
}
</style>