<template>
  <el-drawer :wrapperClosable="false" custom-class="room-add-room" :title="title || '编辑'" :visible="drawer"
    :before-close="close" :direction="'rtl'" :size="350">

    <div class="add-room-body d-f">
      <div class="add-room-content">
        <div class="add-room-title require">姓名</div>
        <el-input v-model="houseMaster.dormName" placeholder="请填写名字" show-word-limit />
        <!-- <div class="add-room-title require">性别</div>
        <el-select style="width: 100%" v-model="houseMaster.ssex">
          <el-option label="男" value="0">男</el-option>
          <el-option label="女" value="1">女</el-option>
          <el-option label="未知" value="2">未知</el-option>
        </el-select> -->
        <div class="add-room-title require">手机号码</div>
        <el-input v-model="houseMaster.phone" placeholder="请填写手机号码" />
        <div class="add-room-title ">类型</div>
        <el-select style="width: 100%" v-model="houseMaster.dormTypeName">
          <el-option v-for="item in list" :label="item" :key="item" :value="item">{{ item }}</el-option>
        </el-select>

        <div class="add-room-title">身份证号</div>
        <el-input v-model="houseMaster.identityNo" placeholder="请填写身份证号" />
        <div class="add-room-title d-f c-p jc-sb" @click="$emit('fix')">

          <span>修改密码 </span>
          <i class="el-icon-arrow-right c-p"></i>
        </div>
      </div>
      <div class="add-room-submit w-100_ d-f ai-c">
        <el-button type="primary" @click="addRoomFunc">保 存</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
export default {
  name: "room-body-add-room",
  data() {
    return {
      drawer: false, //  抽屉
      houseMaster: {
        dormName: "",
        identityNo: "", // 身份证
        phone: "",
        // ssex: "0",
        dormTypeName: ""
      },
      loading: false,
      options: [],
    };
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    addRoomFunc() {
      const warning = {
        dormName: "请输入姓名",
        // identityNo: "请输入身份证号码",
        phone: "请输入手机号码",
        // ssex: "请选择性别",
      };
      // if (!this.isModify) {
      //   warning.password = "请输入管理员密码";
      // }
      const list = Object.keys(warning);
      let canIgo = true;
      for (let i = 0; i < list.length; i++) {
        if (!String(this.houseMaster[list[i]])) {
          this.$message.error(warning[list[i]]);
          canIgo = false;
          break;
        }
      }
      // if (
      //   !/^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/.test(
      //     this.houseMaster.identityNo
      //   )
      // ) {
      //   this.$message.error("请输入正确的身份证号码");
      //   return;
      // }
      if (
        !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
          this.houseMaster.phone
        )
      ) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (canIgo) {
        this.$emit("submit", this.houseMaster);
      }
    },
  },
  watch: {
    drawer() {
      this.houseMaster = {
        dormName: "",
        identityNo: "", // 身份证
        phone: "",
        ssex: "0",
      };
    },
    visible(val) {
      this.drawer = val;
    },
    data(val) {
      this.houseMaster = val;
    },
  },
  props: {
    visible: Boolean,
    title: String,
    data: Object,
    list: Array,
    showFixPass: Boolean
  },
};
</script>

<style lang="scss" scoped></style>